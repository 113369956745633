// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-1-js": () => import("./../../../src/pages/article1.js" /* webpackChunkName: "component---src-pages-article-1-js" */),
  "component---src-pages-browser-js": () => import("./../../../src/pages/browser.js" /* webpackChunkName: "component---src-pages-browser-js" */),
  "component---src-pages-de-browser-js": () => import("./../../../src/pages/de/browser.js" /* webpackChunkName: "component---src-pages-de-browser-js" */),
  "component---src-pages-de-feedback-js": () => import("./../../../src/pages/de/feedback.js" /* webpackChunkName: "component---src-pages-de-feedback-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-protection-js": () => import("./../../../src/pages/de/protection.js" /* webpackChunkName: "component---src-pages-de-protection-js" */),
  "component---src-pages-de-protection-success-js": () => import("./../../../src/pages/de/protection-success.js" /* webpackChunkName: "component---src-pages-de-protection-success-js" */),
  "component---src-pages-de-protection-uninstall-js": () => import("./../../../src/pages/de/protection-uninstall.js" /* webpackChunkName: "component---src-pages-de-protection-uninstall-js" */),
  "component---src-pages-de-success-js": () => import("./../../../src/pages/de/success.js" /* webpackChunkName: "component---src-pages-de-success-js" */),
  "component---src-pages-de-uninstall-js": () => import("./../../../src/pages/de/uninstall.js" /* webpackChunkName: "component---src-pages-de-uninstall-js" */),
  "component---src-pages-en-browser-js": () => import("./../../../src/pages/en/browser.js" /* webpackChunkName: "component---src-pages-en-browser-js" */),
  "component---src-pages-en-feedback-js": () => import("./../../../src/pages/en/feedback.js" /* webpackChunkName: "component---src-pages-en-feedback-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-protection-js": () => import("./../../../src/pages/en/protection.js" /* webpackChunkName: "component---src-pages-en-protection-js" */),
  "component---src-pages-en-protection-success-js": () => import("./../../../src/pages/en/protection-success.js" /* webpackChunkName: "component---src-pages-en-protection-success-js" */),
  "component---src-pages-en-protection-uninstall-js": () => import("./../../../src/pages/en/protection-uninstall.js" /* webpackChunkName: "component---src-pages-en-protection-uninstall-js" */),
  "component---src-pages-en-success-js": () => import("./../../../src/pages/en/success.js" /* webpackChunkName: "component---src-pages-en-success-js" */),
  "component---src-pages-en-uninstall-js": () => import("./../../../src/pages/en/uninstall.js" /* webpackChunkName: "component---src-pages-en-uninstall-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-financial-search-js": () => import("./../../../src/pages/financial-search.js" /* webpackChunkName: "component---src-pages-financial-search-js" */),
  "component---src-pages-health-search-js": () => import("./../../../src/pages/health-search.js" /* webpackChunkName: "component---src-pages-health-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-search-js": () => import("./../../../src/pages/news-search.js" /* webpackChunkName: "component---src-pages-news-search-js" */),
  "component---src-pages-newtab-js": () => import("./../../../src/pages/newtab.js" /* webpackChunkName: "component---src-pages-newtab-js" */),
  "component---src-pages-nl-browser-js": () => import("./../../../src/pages/nl/browser.js" /* webpackChunkName: "component---src-pages-nl-browser-js" */),
  "component---src-pages-nl-index-js": () => import("./../../../src/pages/nl/index.js" /* webpackChunkName: "component---src-pages-nl-index-js" */),
  "component---src-pages-price-search-js": () => import("./../../../src/pages/price-search.js" /* webpackChunkName: "component---src-pages-price-search-js" */),
  "component---src-pages-protection-js": () => import("./../../../src/pages/protection.js" /* webpackChunkName: "component---src-pages-protection-js" */),
  "component---src-pages-protection-success-js": () => import("./../../../src/pages/protection-success.js" /* webpackChunkName: "component---src-pages-protection-success-js" */),
  "component---src-pages-protection-uninstall-js": () => import("./../../../src/pages/protection-uninstall.js" /* webpackChunkName: "component---src-pages-protection-uninstall-js" */),
  "component---src-pages-startpage-extension-js": () => import("./../../../src/pages/startpage-extension.js" /* webpackChunkName: "component---src-pages-startpage-extension-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-uninstall-js": () => import("./../../../src/pages/uninstall.js" /* webpackChunkName: "component---src-pages-uninstall-js" */)
}

